<div
  class="flex"
  [ngClass]="{
    'flex-row items-start gap-8': props['isHorizontal'],
    'flex-col gap-1.5': !props['isHorizontal'],
  }">
  <label
    class="block font-semibold leading-3"
    [for]="id"
    [ngClass]="{
      'basis-[160px] shrink-0': props['isHorizontal'],
    }">
    {{ props.label }}
    @if (!props.required && props.label && !props.hideOptional) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <mat-form-field class="w-full">
    <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <input
      data-testid="date-picker-input"
      matInput
      [matDatepicker]="picker"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [id]="id"
      [min]="field.props.min"
      [max]="field.props.max" />
  </mat-form-field>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
