import { Component, HostListener } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileValueAccessorDirective } from '@cat-ai-us-fe/forms/util';
import { FileNameFromUrlPipe } from '@cat-ai-us-fe/shared/util';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'cat-ai-file',
  standalone: true,
  imports: [
    FormlyModule,
    ReactiveFormsModule,
    MatIconModule,
    FileValueAccessorDirective,
    MatButtonModule,
    ErrorMessageComponent,
    FileNameFromUrlPipe,
    MatTooltip,
  ],
  templateUrl: './file.component.html',
})
export class FileComponent extends FieldType<FieldTypeConfig> {
  isDragOver = false;

  @HostListener('document:dragover', ['$event'])
  onDragDropFileVerifyZone(event: any) {
    if (event.target?.matches('label.dropzone')) {
      if (!this.isDragOver) {
        this.isDragOver = true;
      }
      event.stopPropagation();
      event.preventDefault();
    } else {
      event.preventDefault();
      event.dataTransfer.effectAllowed = 'none';
      event.dataTransfer.dropEffect = 'none';
    }
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (this.props.enablePaste) {
      const items: any = event.clipboardData?.items;
      if (items) {
        const files = [];
        for (const item of items) {
          const file = item.getAsFile();
          files.push(file);
        }
        this.setFilesToControl(files);
      }
    }
  }

  onRemoveFile(e: MouseEvent, index: number) {
    e.preventDefault();
    const value = this.value as File[] | null;
    if (!value) {
      return;
    }

    value.splice(index, 1);

    //TBD: remove file from server too?
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();

    const droppedFiles = $event.dataTransfer?.files;
    if (!droppedFiles?.length) {
      return;
    }
    const files = [];
    for (let index = 0; index < droppedFiles.length; index++) {
      const file: File | any = droppedFiles.item(index);
      files.push(file);
    }
    this.setFilesToControl(files);

    this.isDragOver = false;
  }

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files?.length) {
      const files = Array.from(input.files);
      this.formControl.markAsDirty();
      this.setFilesToControl(files);

      input.value = '';
    }
  }

  private setFilesToControl(files: any[]) {
    if (this.props.multiple) {
      const value = (this.value as File[]) ?? [];
      this.formControl.setValue([...value, ...files]);
    } else {
      this.formControl.setValue([files[0]]);
    }
  }
}
