<div>
  <label class="block mb-1.5 font-semibold leading-3" [for]="id">
    {{ props.label }}
    @if (!props.required && props.label) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <div
    class="w-full cat-file-input"
    [class.cat-file-input_error]="formControl.errors && formControl.touched">
    <input
      data-testid="file-input"
      type="file"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || ''"
      [id]="id"
      class="sr-only"
      [multiple]="props.multiple"
      (change)="onFileSelect($event)" />
    <label
      [for]="id"
      class="py-4 px-6 flex flex-col gap-3 items-center justify-center dropzone  {{
        props.labelClass
      }} {{ isDragOver ? 'bg-slate-300/50' : '' }}"
      (drop)="onDrop($event)"
      (dragend)="isDragOver = false"
      (dragleave)="isDragOver = false"
      (dragenter)="isDragOver = false">
      <div
        data-testid="file-upload-icon"
        [class.-z-10]="isDragOver"
        class="flex rounded-full bg-gray-100 p-2 text-gray-600">
        <mat-icon class="material-icons-outlined">file_upload</mat-icon>
      </div>
      <div [class.-z-10]="isDragOver">
        @if (value?.length > 0 && !props.hideFiles) {
          @for (file of value; track file; let i = $index) {
            <div class="flex items-center gap-1">
              <span data-testid="file-name-text" class="max-w-full break-all">
                @if (props.disableNameFormatting) {
                  {{ file.name }}
                } @else {
                  {{ file.name | fileNameFromUrl }}
                }
              </span>
              <button
                data-testid="remove-image-btn"
                mat-mini-fab
                color="basic"
                (click)="onRemoveFile($event, i)">
                <mat-icon class="material-icons-outlined">close</mat-icon>
              </button>
            </div>
          }
        } @else {
          @if (props.placeholder) {
            <span class="cursor-pointer">
              {{ props.placeholder }}
            </span>
          } @else {
            <div class="flex flex-col">
              <p class="text-center leading-4">
                <a class="text-primary-700 cursor-pointer">Click to upload</a>
                or drag and drop
              </p>
              <!-- <span class="text-xs">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </span> -->
              @if (props.extensionsText) {
                <span class="text-xs">
                  {{ props.extensionsText }}
                </span>
              }
              @if (props.enablePaste) {
                <p
                  data-testid="counter-6"
                  class="!mt-4 text-gray-600 text-center font-semibold">
                  or press Ctrl (⌘) + V to paste
                </p>
              }
            </div>
          }
        }
      </div>
    </label>

    @if (props['attachments'] && props['attachments'].length > 0) {
      <div class="flex flex-wrap gap-1 mt-1.5">
        @for (attachment of props['attachments']; track attachment) {
          <div
            class="border border-gray-300 rounded-md p-2 text-gray-700 text-sm font-semibold inline-flex items-center">
            <div
              class="inline-flex justify-center items-center border border-gray-200 rounded-sm p-1 mr-3">
              <mat-icon class="material-icons-outlined !w-5 !h-5 text-[20px]">
                description
              </mat-icon>
            </div>
            <span>{{ attachment.original_name }}</span>
          </div>
        }
      </div>
    }
  </div>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
