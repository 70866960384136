import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'cat-ai-forms-divider',
  standalone: true,
  imports: [],
  templateUrl: './forms-divider.component.html',
})
export class FormsDividerComponent extends FieldWrapper {}
