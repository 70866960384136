import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FindOptionPipe } from '@cat-ai-us-fe/forms/util';
import { MultiselectOptionComponent } from '../multiselect-option/multiselect-option.component';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'cat-ai-select',
  standalone: true,
  imports: [
    FormlyModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    FormlySelectModule,
    CommonModule,
    MatButtonModule,
    FindOptionPipe,
    MultiselectOptionComponent,
    ErrorMessageComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './select.component.html',
})
export class SelectComponent extends FieldType<FieldTypeConfig> {
  @ViewChild('select') select!: MatSelect;

  open() {
    this.select.open();
  }

  onAddFieldClick() {
    this.props['onAddFieldClick']();
  }

  onRemoveFieldClick() {
    this.props['onRemoveFieldClick']();
  }

  onRemoveOptionClick(index: number) {
    const options = this.formControl.value as number[];
    const filteredOptions = options.filter((_, i) => i !== index);
    this.formControl.setValue(filteredOptions);
  }
}
